.app_header {
  background-color: #8b0000;
  height: 70px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rssb-logo {
  height: 60px;
  padding: 0 10px;
}
.app_heading {
  font-size: 28px;
  font-weight: 600;
}
@media screen and (max-width: 600px) {
  .app_heading {
    font-size: 16px;
  }
}